import React, { useEffect, useState } from 'react';
import { getAccount } from '../Hooks/useAccounts';
import { getPoolInfoLimit, getPoolLength, getUser } from '../Hooks/useStake';
import Footer from './Footers';
import Header from './Header';
import LockedStackCont from './LockedStackCont';
// import logoicon from '../Assets/images/logoicon.png'
import lefthead from '../Assets/genesis/lefthead.png';
import righthead from '../Assets/genesis/righthead.png';
const LockedStake = () => {
    const [poolLimit, setPoolLimit] = useState([]);
    const [poolLength, setPoolLength] = useState(0);
    const [accountInfo, setAccountInfo] = useState(getAccount());

    useEffect(() => {
        getData();
    })

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
    }, [])

    const getData = async () => {
        var data = await getPoolLength()
        if (data) setPoolLength(data);
        var pool = await getPoolInfoLimit(0, data);
       

        if (pool) {
            setPoolLimit(pool);
        }
    }

    
    return (
        <div className='lockedstake'>

            <Header />
            <div className='allsection'>

                <div className='container container-theme mt-4'>
            
                    <div className='br-14 bgtheme text-dark p-sm-5 p-4 text-center bannerbox bannerlocked  justify-content-center position-relative'>
                        <div class="lines">
                            <div class="line1"></div>
                            <div class="line1"></div>
                            <div class="line1"></div>
                            <div class="line1"></div>
                        </div>
                        <span className='greengradientleft'></span>
                        <span className='greengradientright'></span>
                       
                        <div className='alltitle'><img src={lefthead} className='lefthead' alt='' /><img src={righthead} className='righthead' alt='' /> <h1 className='h1tag lockedtitle'>{"{Locked Staking}"}</h1></div>                    </div>
                    <div className='row mb-5'>

                     {poolLimit
                            && poolLimit.map((val, index) => <LockedStackCont datas={val} pid={index} accountInfo={accountInfo} />
                            )} 





                    </div>


                </div>

            </div>
            <Footer />
        </div>
    )
}

export default LockedStake