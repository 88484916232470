import React, { useState,useEffect } from 'react';
import {Navbar,Nav,NavDropdown} from 'react-bootstrap'
import logo from "../Assets/genesis/logo.png"
import { NavLink } from 'react-router-dom';
import Connect from './Modal/ConnectModal';
import { getAccount } from '../Hooks/useAccounts';
import { UseProvider } from '../Hooks/UseWeb3';
import {style,iconTheme,position} from '../Hooks/useToast'
import toast, { Toaster } from 'react-hot-toast';
import { getSettingsData } from '../Hooks/UseBackend';
import {Button} from 'react-bootstrap';

const Header = () =>{
    const[connect, setConnect] = useState(false)
    const [accountInfo, setAccountInfo] = useState("");
    const [settingdata,setsettingdata] = useState([])

  useEffect(()=>{
    setAccountInfo(getAccount());
    getData()
  },[])


  const logOut = async () => {
    setAccountInfo("");
    localStorage.removeItem("accountInfo")
    if (localStorage.getItem("walletconnect") != null) {
        const provider = await UseProvider();
        await provider.disconnect()
    }

    
    toast.success("Wallet Disconnected successfully", {
      position:position.position,
      style:style,
      iconTheme: iconTheme,
  })
  setTimeout(() => {
      
      window.location.reload()
  }, 1000);
}

   

    const getData =async() =>{
        var settingdata = await getSettingsData();
  setsettingdata(settingdata);
    }

    return(
       <div className='header'>
        {connect && <Connect onDismiss={() => setConnect(false)}/>}
        <Navbar bg="dark" expand="lg" variant="dark">
            <div className='container container-theme'>
                <Navbar.Brand href="/">
                    <img src={logo} className="logo"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className='header-content' id="basic-navbar-nav">
                <Nav className="">
                    <NavLink className="nav-link" to="/">Home</NavLink>
                    {/* <NavLink className="nav-link" to="/launchpad">Launchpad</NavLink> */}
                    {/* <NavLink className="nav-link" to="/staking">Locked Staking</NavLink> */}
                    {/* <NavLink className="nav-link" to="/news">News</NavLink> */}
                         
                     <NavLink className="nav-link" to="/map">Map</NavLink>
                     <NavLink className="nav-link" target="blank" to="https://api.genesis-pt.technology/Images/genesisWP.pdf">Whitepaper</NavLink>
                </Nav>

              
               
                {/* {(accountInfo == "" || accountInfo == null)?
                    <button className='btn userbtn headingbtn ms-lg-3' onClick={() => setConnect(true)}>Connect Wallet</button> :
                    <button className='btn userbtn headingbtn ms-lg-3' onClick={() => logOut()}>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38,42)}`}</button>} */}
           
                </Navbar.Collapse>
               
                </div>
        </Navbar>
       </div>
    )
}

export default Header