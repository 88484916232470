import React, { useState, useEffect, useRef } from 'react';
import { Tab, Tabs, Accordion, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Footer from './Footers';
import Header from './Header';
import lefthead from '../Assets/genesis/lefthead.png';
import righthead from '../Assets/genesis/righthead.png';
import partner1 from '../Assets/genesis/animoca.png';
import partner2 from '../Assets/genesis/avalanche.png';
import partner3 from '../Assets/genesis/binance-labs.png';
import numberbox from '../Assets/genesis/number box.png';
import partner4 from '../Assets/genesis/cfund.png';
import { FiCopy } from "react-icons/fi";
import CopyToClipboard from "react-copy-to-clipboard";
import Lottie from "lottie-react";
import gensislogo from "../Assets/genesis/genesislogo.gif";
import genesisback from "../Assets/genesis/landingpageback.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Codedetail from './Modal/codedetailmodal';
import { GetCms, imageCmsData, terminalcmsData } from '../Hooks/UseBackend'
import ReactHtmlParser from 'react-html-parser';
import thu from "../Assets/genesis/3in1.gif"
import backurl from '../lib/config';

const Homepage = () => {

    const [cmsdata, setCmsdata] = useState({});
    const [cmsimage, setCmsimagedata] = useState({})
    const [cmsterminal, setCmsterminaldata] = useState({})

    const [initialload, setInitialload] = useState(true)

    const [partnerimg, setPartnerimg] = useState([
        { "id": 1, "image": partner1 }, { "id": 2, "image": partner2 }, { "id": 3, "image": partner3 }, { "id": 4, "image": partner4 }, { "id": 5, "image": partner1 }, { "id": 6, "image": partner2 },
        { "id": 7, "image": partner3 }, { "id": 8, "image": partner1 }, { "id": 9, "image": partner2 }, { "id": 10, "image": partner1 }, { "id": 11, "image": partner3 }, { "id": 12, "image": partner1 }
    ])
    const [copySuccess, setCopySuccess] = useState('');

    const [codedetail, setCodedetail] = useState(false)

    useEffect(() => {
        let val = ReactHtmlParser(
            cmsterminal[0]?.content
        )
        console.log(val, "ReactHtmlParser___cmsterminal")
    }, [cmsterminal])

    const options = {
        margin: 10,
        responsiveClass: true,
        loop: true,
        nav: true,
        dots: false,
        autoplay: false,
        navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 1,

            }
        },
    }
    const CmsData = async () => {
        const result = await GetCms()
        console.log("resultresult", result);
        if (result) {
            setCmsdata(result)
        }

    }
    const imagecms = async () => {
        const result = await imageCmsData()
        console.log("resultresultssss", result);
        if (result) {
            setCmsimagedata(result)
        }

    }

    const terminalcms = async () => {
        const result = await terminalcmsData()
        console.log("resu", result);
        if (result) {
            setCmsterminaldata(result)
        }

    }
    console.log("cmsterminal", cmsterminal);
    useEffect(() => {
        CmsData()
        imagecms()
        terminalcms()
        setTimeout(() => {
            setInitialload(false); // count is 0 here
        }, 12900);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    return (
        <>

            {codedetail && <Codedetail onDismiss={() => setCodedetail(false)} />}

            {initialload ?
                <div className='fullimg'>
                    <img src={genesisback} className='img-fluid w-100 h-100vh' alt='initialimg' />
                </div>



                :
                <div className='launchpad allitems'>
                    <Header />
                    <div className='allsection'>

                        <div className='logohome'
                        //  style= {{background:`${backurl}/image/${cmsimage[0]?.image}`, backgroundSize:"100% 100%", backgroundPosition:"center"  }}
                        >

                            <img src={`${backurl}/image/${cmsimage[0]?.image}`} className='img-fluid genesisimage w-100 h-100vh' alt='initialimg' />
                            <img src={genesisback} className='img-fluid w-100 h-100vh' alt='initialimg' />
                        </div>

                        <div className='concept alllist alllist1'>
                            <div className='container container-theme'>
                                <div className='alltitle'><img src={lefthead} className='lefthead' alt='' /><img src={righthead} className='righthead' alt='' />
                                    {/* <h1 className='h1tag'>{"{Concept of Genesis}"}</h1> */}

                                    <h1 className='h1tag'>{`{${cmsdata[0]?.identifier == "Concept-of-Genesis" ? cmsdata[0]?.content : <></>}}`}</h1>

                                </div>
                                {/* <p className='lighttxt text-center mt-4 px-3'>Lorem ipsum dolor sit amet consectetur. Ullamcorper pellentesque sit vel ullamcorper tincidunt aliquet sed quis. Netus blandit scelerisque mattis nibh felis non felis nisi elit. Feugiat convallis quis ante sed tincidunt ut. Malesuada neque eu interdum ut ornare viverra viverra sagittis. Vitae ac fermentum lacus elit. Habitant morbi interdum arcu magna dictum diam. Sed vel arcu curabitur quis. Sed duis morbi porttitor interdum nec tristique aliquam. Orci montes cras tempus viverra viverra malesuada id. Vitae enim donec volutpat bibendum sed pretium ullamcorper nunc. Interdum a senectus pharetra maecenas magna </p> */}
                                <p className='lighttxt text-center mt-4 px-3'>{`${cmsdata[1]?.identifier == "para-content" ? cmsdata[1]?.content : <></>}`} </p>

                                <div className='text-center pt-2'>
                                    <a target="blank" href="https://api.genesis-pt.technology/Images/genesisWP.pdf" className='btn userbtn btnflexnew headingbtn mt-3 me-0 me-sm-4'>{`${cmsdata[2]?.identifier == "Develop-with-genesis" ? cmsdata[2]?.content : <></>}`}</a>
                                    <a target="blank" href="https://api.genesis-pt.technology/Images/genesisWP.pdf" className='btn userbtn btnflexnew blkbtn mt-3 headingbtn'>{`${cmsdata[3]?.identifier == "Download-whitepaper" ? cmsdata[3]?.content : <></>}`}</a></div>
                            </div>
                        </div>

                        {/* <div className='concept funds fundss alllist'>
                            <div className='container container-theme'>
                                <div className='alltitle'><img src={lefthead} className='lefthead' alt='' /><img src={righthead} className='righthead' alt='' /> <h1 className='h1tag'>{`{${cmsdata[4]?.identifier == "Funds" ? cmsdata[4]?.content : <></>}}`}</h1></div>
                                <OwlCarousel className='owl-theme' {...options}>
                                    {console.log("cmsimage",cmsimage)}
                                 
                                    {cmsimage.map((e, i) => {
                                        return ((e.identifier == 'genesis' && i!=0) ?
                                            <div class='item mb-4'>
                                                <div className='row text-center'>
                                                    {cmsimage.map((e, i) => {
                                                        return ((e.identifier == 'genesis'&& i!=0)  ?
                                                            <div className='col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 mt-5 mb-3 col_partners'>
                                                                <img src={`${backurl}/image/${e?.image}`} className='img-fluid partnerimg' alt='image' />
                                                            </div>
                                                            : '')
                                                    }
                                                    )}
                                                </div>
                                            </div>
                                            :
                                            <></>)
                                    }
                                    )}
        
                                </OwlCarousel>
                            </div>
                        </div> */}

                        <div className='concept funds alllist'>
                        {cmsdata.length > 0 ?  <div className='container container-theme'>
                                <div className='alltitle'><img src={lefthead} className='lefthead' alt='' /><img src={righthead} className='righthead' alt='' /> <h1 className='h1tag'>{`{${cmsdata[2]?.identifier == "Develop-with-genesis" ? cmsdata[2]?.content : <></>}}`}</h1></div>
                                <p className='lighttxt text-center mt-4 px-3'>{`${cmsdata[5]?.identifier == "para" ? cmsdata[5]?.content : <></>}`}</p>
                                <div className='text-center pt-3'><button className='btn userbtn headingbtn'>{`${cmsdata[6]?.identifier == "View-on-Gitbook" ? cmsdata[6]?.content : <></>}`}</button></div>
                                <div className='overalltab mt-4'>
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                                <div className='d-flex justify-content-between'><p className='mb-0'>Terminal</p><p className='mb-0 closes'>x</p></div>
                                            </button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"><div className='d-flex justify-content-between'><p className='mb-0'>Terminal</p><p className='mb-0 closes'>x</p></div></button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false"><div className='d-flex justify-content-between'><p className='mb-0'>Terminal</p><p className='mb-0 closes'>x</p></div></button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="lasted-tab" data-bs-toggle="tab" data-bs-target="#lasted" type="button" role="tab" aria-controls="lasted" aria-selected="false"><div className='d-flex justify-content-between'><p className='mb-0'>Terminal</p><p className='mb-0 closes'>x</p></div></button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="lastedss-tab" data-bs-toggle="tab" data-bs-target="#lastedss" type="button" role="tab" aria-controls="lastedss" aria-selected="false"><div className='d-flex justify-content-between'></div></button>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            {/* <CopyToClipboard text={
                                                // 'startTime = _values[8];endTime = _values[9];if (startTime = block.timestamp)isPresaleOpen = true;liquidityPercent = _values[7];depolymentFee = IPresaleProxy(PROXY).getDeploymentFee();liquidityPercent = _values[7];depolymentFee = IPresaleProxy(PROXY).getDeploymentFee()'
                                                    cmsterminal[0]?.content
                                               }>
                                                <div className='text-end'><button type='button' className='btn copybtn'><FiCopy /></button></div>
                                            </CopyToClipboard> */}
                                            {
                                                cmsterminal[0]?.identifier == "terminal1" &&
                                                (
                                                    <>
                                                        {
                                                            cmsterminal && cmsterminal[0]?.content &&

                                                            ReactHtmlParser(
                                                                cmsterminal[0]?.content
                                                            )
                                                        }

                                                    </>
                                                )
                                            }

                                            {/* <p>startTime = _values[8];<br></br>endTime = _values[9];<br></br>if (startTime = block.timestamp)isPresaleOpen = true;<br></br>liquidityPercent = _values[7];<br></br>depolymentFee = IPresaleProxy(PROXY).getDeploymentFee();<br></br>liquidityPercent = _values[7];<br></br>depolymentFee = IPresaleProxy(PROXY).getDeploymentFee();<br></br>depolymentFee = IPresaleProxy(PROXY).getDeploymentFee();<br></br>depolymentFee = IPresaleProxy(PROXY).getDeploymentFee();<br></br>depolymentFee = IPresaleProxy(PROXY).getDeploymentFee();</p> */}
                                        </div>
                                        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                            {/* <CopyToClipboard text={'startTime = _values[8];endTime = _values[9];if (startTime = block.timestamp)isPresaleOpen = true;liquidityPercent = _values[7];depolymentFee = IPresaleProxy(PROXY).getDeploymentFee();liquidityPercent = _values[7];depolymentFee = IPresaleProxy(PROXY).getDeploymentFee()'}>
                                                <div className='text-end'><button type='button' className='btn copybtn'><FiCopy /></button></div>
                                            </CopyToClipboard> */}
                                            {
                                                cmsterminal[1]?.identifier == "terminal2" &&
                                                (
                                                    <>
                                                        {
                                                            cmsterminal && cmsterminal[1]?.content && ReactHtmlParser(
                                                                cmsterminal[1]?.content
                                                            )
                                                        }

                                                    </>
                                                )
                                            }
                                            {/* <p>startTime = _values[8];<br></br>endTime = _values[9];<br></br>if (startTime = block.timestamp)isPresaleOpen = true;<br></br>liquidityPercent = _values[7];<br></br>depolymentFee = IPresaleProxy(PROXY).getDeploymentFee();<br></br>liquidityPercent = _values[7];<br></br>depolymentFee = IPresaleProxy(PROXY).getDeploymentFee();</p> */}
                                        </div>
                                        <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                            {/* <CopyToClipboard text={'startTime = _values[8];endTime = _values[9];if (startTime = block.timestamp)isPresaleOpen = true;liquidityPercent = _values[7];depolymentFee = IPresaleProxy(PROXY).getDeploymentFee();liquidityPercent = _values[7];depolymentFee = IPresaleProxy(PROXY).getDeploymentFee()'}>
                                                <div className='text-end'><button type='button' className='btn copybtn'><FiCopy /></button></div>
                                            </CopyToClipboard> */}
                                            {
                                                cmsterminal[2]?.identifier == "terminal3" &&
                                                (
                                                    <>
                                                        {
                                                            cmsterminal && cmsterminal[2]?.content && ReactHtmlParser(
                                                                cmsterminal[2]?.content
                                                            )
                                                        }

                                                    </>
                                                )
                                            }
                                            {/* <p>startTime = _values[8];<br></br>endTime = _values[9];<br></br>if (startTime = block.timestamp)isPresaleOpen = true;<br></br>liquidityPercent = _values[7];<br></br>depolymentFee = IPresaleProxy(PROXY).getDeploymentFee();<br></br>liquidityPercent = _values[7];<br></br>depolymentFee = IPresaleProxy(PROXY).getDeploymentFee();</p> */}
                                        </div>
                                        <div class="tab-pane fade" id="lasted" role="tabpanel" aria-labelledby="lasted-tab">
                                            {/* <CopyToClipboard text={'startTime = _values[8];endTime = _values[9];if (startTime = block.timestamp)isPresaleOpen = true;liquidityPercent = _values[7];depolymentFee = IPresaleProxy(PROXY).getDeploymentFee();liquidityPercent = _values[7];depolymentFee = IPresaleProxy(PROXY).getDeploymentFee()'}>
                                                <div className='text-end'><button type='button' className='btn copybtn'><FiCopy /></button></div>
                                            </CopyToClipboard> */}
                                            {
                                                cmsterminal[3]?.identifier == "terminal4" &&
                                                (
                                                    <>
                                                        {
                                                            cmsterminal && cmsterminal[3]?.content && ReactHtmlParser(
                                                                cmsterminal[3]?.content
                                                            )
                                                        }

                                                    </>
                                                )
                                            }
                                            {/* <p>startTime = _values[8];<br></br>endTime = _values[9];<br></br>if (startTime = block.timestamp)isPresaleOpen = true;<br></br>liquidityPercent = _values[7];<br></br>depolymentFee = IPresaleProxy(PROXY).getDeploymentFee();<br></br>liquidityPercent = _values[7];<br></br>depolymentFee = IPresaleProxy(PROXY).getDeploymentFee();</p> */}
                                        </div>
                                        <div class="tab-pane fade" id="lastedss" role="tabpanel" aria-labelledby="lastedss-tab">

                                        </div>
                                    </div>
                                </div>
                            </div> : <></>}
                           
                        </div>


                        <div className='concept faq alllist'>
                            <div className='container container-theme'>
                                <div className='alltitle'><img src={lefthead} className='lefthead' alt='' /><img src={righthead} className='righthead' alt='' /> <h1 className='h1tag'>{`{${cmsdata[7]?.identifier == "Examples" ? cmsdata[7]?.content : <></>}}`}</h1></div>
                                <div className='pt-4'>
                                    <OwlCarousel className='owl-theme' {...options}>
                                        {partnerimg.map((e, i) =>
                                            <div class='item'>
                                                <Accordion
                                                //  defaultActiveKey="0"
                                                >
                                                    <div className='row mt-sm-3  mt-3 mb-3 align-items-start'>
                                                        <div className='col-2 col-md-1 mt-4'>
                                                            <h3 className='numberbox'>1</h3>
                                                        </div>
                                                        <div className='col-10 col-md-11 mt-4'>
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header><p className='mb-0'>{`${cmsdata[8]?.identifier == "LZEndpointMock.sol" ? cmsdata[8]?.content : <></>}`}</p></Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className='row'>
                                                                        <div className='col-md-6 mt-2'>
                                                                            <p className='mb-0 mt-3'>{`${cmsdata[9]?.identifier == "content1" ? cmsdata[9]?.content : <></>}`}</p>
                                                                        </div>
                                                                        <div className='col-md-6 mt-2'>
                                                                            <div className='viewdetail'>
                                                                                <p>{`${cmsdata[10]?.identifier == "content2" ? cmsdata[10]?.content : <></>}`}</p>
                                                                                <Button className='w-100' variant="dark" onClick={() => { setCodedetail(true) }}>View More Details</Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </div>
                                                    </div>
                                                    {/* .slice(0,700)+".." */}
                                                    <div className='row mt-sm-3  mt-3 mb-3'>
                                                        <div className='col-2 col-md-1 mt-4'>
                                                            <h3 className='numberbox'>2</h3>
                                                        </div>
                                                        <div className='col-10 col-md-11 mt-4'>
                                                            <Accordion.Item eventKey="1">
                                                                <Accordion.Header><p className='mb-0'>{`${cmsdata[12]?.identifier == "PingPong.sol" ? cmsdata[12]?.content : <></>}`}</p></Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className='row'>
                                                                        <div className='col-md-6 mt-2'>
                                                                            <p className='mb-0  mt-3'>{`${cmsdata[9]?.identifier == "content1" ? cmsdata[9]?.content : <></>}`}</p>
                                                                        </div>
                                                                        <div className='col-md-6 mt-2'>
                                                                            <div className='viewdetail'>
                                                                                <p>{`${cmsdata[10]?.identifier == "content2" ? cmsdata[10]?.content : <></>}`}</p>
                                                                                <Button className='w-100' variant="dark" onClick={() => { setCodedetail(true) }}>View More Details</Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </div>
                                                    </div>

                                                    <div className='row mt-sm-3  mt-3 mb-3'>
                                                        <div className='col-2 col-md-1 mt-4'>
                                                            <h3 className='numberbox'>3</h3>
                                                        </div>
                                                        <div className='col-10 col-md-11 mt-4'>
                                                            <Accordion.Item eventKey="2">
                                                                <Accordion.Header><p className='mb-0'>{`${cmsdata[13]?.identifier == "OmniCounter.sol" ? cmsdata[13]?.content : <></>}`}</p></Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className='row'>
                                                                        <div className='col-md-6 mt-2'>
                                                                            <p className='mb-0  mt-3' >{`${cmsdata[14]?.identifier == "Lorem" ? cmsdata[14]?.content : <></>}`}

                                                                            </p>
                                                                        </div>
                                                                        <div className='col-md-6 mt-2'>
                                                                            <div className='viewdetail'>
                                                                                <p>{`${cmsdata[15]?.identifier == "Lorem2" ? cmsdata[15]?.content : <></>}`}</p>
                                                                                <Button className='w-100' variant="dark" onClick={() => { setCodedetail(true) }}>View More Details</Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </div>
                                                    </div>
                                                </Accordion>
                                            </div>
                                        )}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>


                        <div className='concept faq alllist'>
                            <div className='container container-theme'>
                                <div className='alltitle'><img src={lefthead} className='lefthead' alt='' /><img src={righthead} className='righthead' alt='' /> <h1 className='h1tag'>{`{${cmsdata[16]?.identifier == "Roadmap of Genesis" ? cmsdata[16]?.content : <></>}}`}</h1></div>
                                <p className='lighttxt text-center mt-4 px-3'>{`${cmsdata[17]?.identifier == "Our Mission" ? cmsdata[17]?.content : <></>}`}</p>
                                <div className='row roadmaprow pt-0'>
                                    <div className='col-md-11 col-lg-10 mx-auto'>
                                    <div className='row mt-5'>
                                        <div className='col-md-2 col-sm-3 col-3'>
                                          <img src={lefthead} className='img-fluid' alt='' />
                                        </div>
                                        <div className='col-md-10 col-sm-9 col-9'>
                                        <h5>{`${cmsdata[18]?.identifier == "title_Research" ? cmsdata[18]?.content : <></>}`}</h5>
                                        <p className='mb-0'>{`${cmsdata[19]?.identifier == "desc_title_Research" ? cmsdata[19]?.content : <></>}`}</p>
                                        </div>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-md-2 col-sm-3 col-3'>
                                          <img src={lefthead} className='img-fluid' alt='' />
                                        </div>
                                        <div className='col-md-10 col-sm-9 col-9'>
                                        <h5>{`${cmsdata[20]?.identifier == "title_Development" ? cmsdata[20]?.content : <></>}`}</h5>
                                        <p  className='mb-0'>{`${cmsdata[21]?.identifier == "desc_title_Development" ? cmsdata[21]?.content : <></>}`}</p>
                                        </div>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-md-2 col-sm-3 col-3'>
                                          <img src={lefthead} className='img-fluid' alt='' />
                                        </div>
                                        <div className='col-md-10 col-sm-9 col-9'>
                                        <h5>{`${cmsdata[22]?.identifier == "title_Ecosystem" ? cmsdata[22]?.content : <></>}`}</h5>
                                        <p  className='mb-0'>{`${cmsdata[23]?.identifier == "desc_title_Ecosystem" ? cmsdata[23]?.content : <></>}`}</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    <Footer />

                </div>
            }
        </>
    )
}

export default Homepage