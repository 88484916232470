import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Footer from './Footers';
import Header from './Header';
import { Tabs, Tab } from 'react-bootstrap';
import Masp from './Modal/MaspModal';
import LaunchpadCont from './LaunchpadCont';
import logoicon from '../Assets/genesis/logo.png'
import { getLaunchpadData } from '../Hooks/UseBackend';
import Typed from 'react-typed';

import lefthead from '../Assets/genesis/lefthead.png';
import righthead from '../Assets/genesis/righthead.png';
// import circle from "../Assets/images/circle.png";



const Launchpad = () => {
    const [masp, setMasp] = useState(false)
    const [totalLaunchPadlength, settotalLaunchPadlength] = useState([])


    useEffect(() => {

        getData()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
    }, [])

    const getData = async () => {

        var data = await getLaunchpadData();
        settotalLaunchPadlength(data)



        //  data.map(async(item,index) =>{
        //  var result = await UsegetSaleInfo(item?.presaleAddress);

        //  })
    }


    return (
        <div className='launchpad'>
            <Header />

            <div className='allsection'>

                <div className='container container-theme my-4'>
                    <div className='br-14 bgtheme text-dark p-sm-5 p-4 text-center bannerbox position-relative'>
                        <div>
                            <div class="lines">
                                <div class="line1"></div>
                                <div class="line1"></div>
                                <div class="line1"></div>
                                {/* <div class="line1"></div> */}
                            </div>
                            <span className='greengradientleft'></span>
                            <span className='greengradientright'></span>
                            {/* <img src={loopsimg} className='loopsimg' alt='targets' />
                            <img src={loopsleftimg} className='loopsleftimg' alt='targets' />
                            <img src={targets} className='targetcircle' alt='targets' /> */}

                            {/* <h1 className='innerheading'>Launchpad</h1> */}

                            <div className='alltitle'><img src={lefthead} className='lefthead' alt='' /><img src={righthead} className='righthead' alt='' /> <h1 className='h1tag'>{"{Launchpad}"}</h1></div>
                           
                            {/* <p>Buy New Tokens Before They Are Launched for Trading</p> */}
                            <div className='mt-3 titles'>
                                <Typed
                                    strings={['Buy New Tokens Before They Are Launched for Trading']}
                                    typeSpeed={100}
                                    cursorChar={"_"}
                                    loop
                                />
                            </div>
                        </div>
                    </div>
                    <div className='showloader minwidth-534 text-center' id="loader_div">
                        <div className='loader ' >
                            <img src={logoicon} className="spin" />

                        </div>

                    </div>
                    <div className='menu_list position-relative'>

                        <div className='notice_link'> <a target="_blank"
                            // href='https://www.labelm.io/newsdetails/63d1edb1ecf0432c7ebd9869' 
                            href='/'
                            className='noticelink'>{"{-Notice-}"}</a></div>

                        {/* <div className='notice_link'> <a href='/news/#latest_article' className='noticelink'>-Notice-</a></div> */}
                        <div className='launchpadall'>
                            <Tabs defaultActiveKey="all" id="uncontrolled-tab-example" className="mb-3 mt-5">
                                <Tab eventKey="all" title="All">
                                    {totalLaunchPadlength && totalLaunchPadlength.map((item, index) => (item.pid == 0 ||item.pid == 1 || item.pid == 2 ? <LaunchpadCont data={item} pid={index} /> : <></>))}
                                </Tab>
                                <Tab eventKey="launch1" title="90 Days">
                                    {totalLaunchPadlength && totalLaunchPadlength.map((item, index) => (item.pid == 0 ? <LaunchpadCont data={item} pid={index} /> : <></>))}
                                </Tab>
                                <Tab eventKey="launch2" title="120 Days" >
                                    {totalLaunchPadlength && totalLaunchPadlength.map((item, index) => (item.pid == 1 ? <LaunchpadCont data={item} pid={index} /> : <></>))}
                                </Tab>
                                <Tab eventKey="launch3" title="180 Days" >
                                    {totalLaunchPadlength && totalLaunchPadlength.map((item, index) => (item.pid == 2 ? <LaunchpadCont data={item} pid={index} /> : <></>))}
                                </Tab>

                            </Tabs>

                        </div>

                    </div>

                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Launchpad